import React from "react";

export default function Virothiumgasdao() {
  return (
    <>
      {" "}
      <div className="displayCenter viromainBox">
        <img
          src="/images/MININGFLOWCHART.png"
          alt="MININGFLOWCHART"
          className="centerImage"
        />
        {/* <h1 style={{ fontSize: "30px", position: "absolute", top: "45%" }}>
          Coming Soon
        </h1> */}
      </div>
    </>
  );
}
